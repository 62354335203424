/**
 * Provides an easy way to add accessible onClick handlers to a React node
 * Ex. <div {...link((event) => window.open('https://salesforce.com')) } />
 */
const link = (action) => ({
  onKeyDown: (event) => {
    if (event.key === 'Enter') {
      action(event);
    }
  },
  onClick: (event) => action(event),
  tabIndex: 0,
  role: 'link',
});

export default link;
