const EVENT_TYPE_ICONS = {
  AUTOMATION: {
    iconAssistiveText: 'Automation',
    iconAssistiveTextKey: 'homepage.event_types.automation',
    iconCategory: 'custom',
    iconName: 'custom106',
    iconStyle: { backgroundColor: '#b17fd1' },
  },
  CUSTOM: {
    iconAssistiveText: 'Event',
    iconAssistiveTextKey: 'homepage.event_types.event',
    iconCategory: 'standard',
    iconName: 'event',
    iconStyle: { backgroundColor: '#f59331' },
  },
  CAMPAIGN: {
    iconAssistiveText: 'Campaign',
    iconAssistiveTextKey: 'homepage.event_types.campaign',
    iconCategory: 'standard',
    iconName: 'campaign',
    iconStyle: { backgroundColor: '#f59331' },
  },
  EMAIL: {
    iconAssistiveText: 'Email',
    iconAssistiveTextKey: 'homepage.event_types.email',
    iconCategory: 'custom',
    iconName: 'custom105',
    iconStyle: { backgroundColor: '#f0b31d' },
  },
  FBPAGE: {
    iconAssistiveText: 'Facebook Page',
    iconAssistiveTextKey: 'homepage.event_types.facebook_page',
    iconCategory: 'custom',
    iconName: 'custom112',
    iconStyle: { backgroundColor: '#3c8bfc' },
  },
  FBPOST: {
    iconAssistiveText: 'Facebook Post',
    iconAssistiveTextKey: 'homepage.event_types.facebook_post',
    iconCategory: 'custom',
    iconName: 'custom112',
    iconStyle: { backgroundColor: '#3c8bfc' },
  },
  MOBILE: {
    iconAssistiveText: 'Mobile',
    iconAssistiveTextKey: 'homepage.event_types.mobile',
    iconCategory: 'custom',
    iconName: 'custom110',
    iconStyle: { backgroundColor: '#45c79c' },
  },
  PUSH: {
    iconAssistiveText: 'Mobile',
    iconAssistiveTextKey: 'homepage.event_types.mobile_push',
    iconCategory: 'custom',
    iconName: 'custom110',
    iconStyle: { backgroundColor: '#45c79c' },
  },
  TWEET: {
    iconAssistiveText: 'Tweet',
    iconAssistiveTextKey: 'homepage.event_types.tweet',
    iconCategory: 'custom',
    iconName: 'custom112',
    iconStyle: { backgroundColor: '#3c8bfc' },
  },
  GROUPCONNECT: {
    iconAssistiveText: 'Group Connect',
    iconAssistiveTextKey: 'homepage.event_types.group_connect',
    iconCategory: 'custom',
    iconName: 'custom110',
    iconStyle: { backgroundColor: '#45c79c' },
  },
};

export default EVENT_TYPE_ICONS;
